@import 'functions';

:root {
  // theme colors
  --primary-rgb : #{cl(#D91C5C)};
  --sec-rgb     : #{cl(#CE9B44)};
  --blue-rgb    : #{cl(#2980b9)};
  --red-rgb     : #{cl(#c0392b)};
  --green-rgb   : #{cl(#27ae60)};
  --yellow-rgb  : #{cl(#f39c12)};

  // colors
  --bg1-rgb     : #{cl(#ededed)};
  --bg2-rgb     : #{cl(#f4f4f4)};
  --bg3-rgb     : #{cl(#fff)};
  --default-rgb : #{cl(#373737)};
  --white-rgb   : #{cl(#fff)};
  --black-rgb   : #{cl(#000)};

  // variables
  --bg1    : rgb(var(--bg1-rgb));
  --bg2    : rgb(var(--bg2-rgb));
  --bg3    : rgb(var(--bg3-rgb));
  --default: rgb(var(--default-rgb));

  // font
  --font-base: 'Roboto', arial, helvetica, sans-serif;

}

body {
  display       : flex;
  flex-direction: column;
  min-height    : 100vh;
  background    : theme('colors.l3');
  font-family   : var(--font-base);
  color         : theme('colors.default');

  &.menu-opened {
    overflow: hidden;
  }
}

.started {
  display       : flex;
  flex-direction: column;
  position      : relative;
  height        : 100%;
  padding       : 64px 86px 48px;

  &-wrapper {
    height : 100vh;
    padding: 12px;
  }

  &-background {
    position     : absolute;
    top          : 0;
    left         : 0;
    width        : 100%;
    height       : 100%;
    border-radius: 16px;
    object-fit   : cover;
  }
}

.logo {
  margin-bottom: auto;
}

.sign {
  font-size  : 32px;
  font-weight: 300;
  line-height: 1.2;
  color      : #fff;
}

.title {
  margin-top : 15px;
  font-size  : 96px;
  font-weight: 600;
  line-height: 1.1;
  color      : #fff;
}

.features {
  display              : grid;
  grid-template-columns: 1fr 1fr 1fr;
  width                : 100%;
  max-width            : 1676px;
  margin-top           : 62px;
  font-size            : 32px;
  line-height          : 1.2;
  color                : #fff;
  text-transform       : uppercase;
  gap                  : 127px;
}

.buttons {
  display       : flex;
  align-items   : center;
  flex-direction: column;
  position      : absolute;
  top           : 78px;
  right         : 58px;
  gap           : 16px;
}

.button {
  height       : 82px;
  padding-right: 32px;
  padding-left : 32px;
  font-size    : 32px;
}

@media (max-height:1060px) {
  .title {
    font-size: 80px;
  }

  .features {
    font-size: 28px;
  }
}

@media (max-width:1400px) {
  .features {
    display: none;
  }
}

@media (max-width:992px) {
  .sign {
    margin-top: 20px;
  }

  .started {
    padding-right: 32px;
    padding-left : 32px;
  }

  .title {
    font-size: 48px;
  }

  .buttons {
    align-items: flex-start;
    position   : relative;
    top        : auto;
    right      : auto;
    margin-top : 20px;
  }
}

@media (max-width:600px) {
  .started {
    display       : flex;
    flex-direction: column;
    padding-top   : 28px;
    padding-bottom: 28px;
  }

  .started-background {
    order  : -10;
    z-index: -1;
  }

  .logo {
    order: -5;
    width: 122px;
  }

  .title {
    order    : -4;
    font-size: 10.5vw;
  }

  .sign {
    font-size: 14px;
  }

  .buttons {
    align-items: center;
    margin-top : 40px;
  }

  .button {
    width    : 100%;
    height   : 52px;
    font-size: 20px;
  }
}

@media (max-width:420px) {
  .started {
    padding-right: 12px;
    padding-left : 12px;

    &-wrapper {
      padding: 8px;
    }
  }
}

.dialog-form {
  width     : 500px;
  max-width : 100%;
  padding   : 40px;
  background: #fff;
}