@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import '@qpokychuk/ripple/index.css';
  @import "@fancyapps/ui/dist/fancybox/fancybox.css";
  @import 'swiper/css';
}

@import '@qpokychuk/fonts/roboto/index.css';
@import '../ui/ui-core';
@import '../scss/frontend--style';